import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { AppConfigService } from '@shared/services/app.config.service';

import { Auth0ConfigService } from '../../shared/services/auth0-config.service';
import { AuthInterceptor } from './auth.interceptor';
import { UserAuthService } from './services/user-auth.service';

export function initConfig(
  appConfigService: AppConfigService,
  auth0Service: Auth0ConfigService
): () => Promise<any> {
  return (): Promise<any> => {
    return appConfigService.loadAppConfig().then(() => {
      return auth0Service.initializeAuth0();
    });
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule, Auth0Module.forRoot()],
  exports: [Auth0Module],
  providers: [
    UserAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService, Auth0ConfigService],
      multi: true,
    },
  ],
})
export class AuthModule {}
