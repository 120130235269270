import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { PERMISSIONS } from '@shared/constants/permissions';
import { Observable, map } from 'rxjs';

@Injectable()
export class UserAuthService {
  private authService = inject(AuthService);

  private readonly _permissionsKey = 'https://trip-arc.com/permissions';

  private readonly _rolesKey = 'https://trip-arc.com/roles';

  private readonly _tenantId = 'https://trip-arc.com/tenant_id';

  logoutWithRedirect(url?: string) {
    url = url || environment.auth0.authorizationParams.redirect_uri;
    this.authService.logout({ logoutParams: { returnTo: url } });
  }

  loginWithRedirect() {
    this.authService.loginWithRedirect();
  }

  getAccessTokenSilently() {
    return this.authService.getAccessTokenSilently();
  }

  get isLoading$() {
    return this.authService.isLoading$;
  }

  get isAuthenticated$() {
    return this.authService.isAuthenticated$;
  }

  get user$() {
    return this.authService.user$;
  }

  get idTokenClaims$() {
    return this.authService.idTokenClaims$;
  }

  get permissions$(): Observable<string[] | null | undefined> {
    return this.idTokenClaims$.pipe(
      map((claims) => claims?.[this._permissionsKey])
    );
  }

  get tenantId$(): Observable<string[] | null | undefined> {
    return this.idTokenClaims$.pipe(map((claims) => claims?.[this._tenantId]));
  }

  get roles$(): Observable<string[] | null | undefined> {
    return this.idTokenClaims$.pipe(map((claims) => claims?.[this._rolesKey]));
  }

  get canModify$(): Observable<boolean> {
    return this.permissions$.pipe(
      map((p) => p?.includes(PERMISSIONS.locales.modify) || false)
    );
  }
}
