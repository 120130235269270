import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import {
  AuthModule,
  StsConfigLoader,
  StsConfigStaticLoader,
} from 'angular-auth-oidc-client';

export const brandLoaderFactory = () => {
  const config$ = {
    authority: 'https://authentication.trip-arc.com',
    redirectUrl: environment.auth0.authorizationParams.redirect_uri,
    postLogoutRedirectUri: environment.auth0.authorizationParams.redirect_uri,
    clientId: 'demo-spa-client',
    scope: 'openid email profile',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    customParamsAuthRequest: {
      acr_values: ``,
    },
  };

  return new StsConfigStaticLoader(config$);
};

@NgModule({
  providers: [],
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: brandLoaderFactory,
        deps: [],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
