import { OnDestroy, Injectable } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable()
export class DestroyerHelper implements OnDestroy {

  subscribers: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscribers.unsubscribe();
  }
}
