export const QUILL_CONFIG = {
  modules: {
    syntax: true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean', 'code'],
    ],
  },
};
