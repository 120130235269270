export const environment = {
  production: false,
  name: 'default',
  apiUrl: 'https://locales.dev.triparcdev.com',
  metatronApiUrl: 'https://triparc-metatron-dev-webapi.azurewebsites.net',
  auth0: {
    domain: 'auth.uat.triparcservices.com',
    clientId: '2b6shrnCaxds6x5Rgp8vF9CwzuxgvGvv',
    authorizationParams: {
      audience: 'https://trip-arc.com/tap',
      redirect_uri: window.location.origin,
    },
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  },
  mapBoxToken: 'pk.eyJ1IjoibWlndWVsaGVuYW9hIiwiYSI6ImNscXh5dWY1YTBqODcybHFnYzQyNXZqOTEifQ.RG648L4l7-13yL5vT9cRLQ'
};
