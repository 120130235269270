import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, concatMap, filter, iif, switchMap } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly apiUrlString = 'locales';
  appAuth = inject(AuthService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Immediately pass through if the request is not targeting '/api/'
    if (!this.canAttachToken(req)) return next.handle(req);

    const isLoaded$ = this.appAuth.isLoading$.pipe(
      filter((isLoading) => !isLoading)
    );

    return isLoaded$.pipe(
      concatMap(() =>
        iif(
          () => this.canAttachToken(req),
          this.appAuth.getAccessTokenSilently().pipe(
            switchMap((token) => {
              const clone = token
                ? req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      `Bearer ${token}`
                    ),
                  })
                : req;
              return next.handle(clone);
            })
          ),
          next.handle(req)
        )
      )
    );
  }

  canAttachToken(req: HttpRequest<any>): boolean {
    return req.url.includes(this.apiUrlString);
  }
}
