import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { HeaderMenuModule } from '@triparc/nexus';

import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './layout.component';

@NgModule({
  declarations: [LayoutComponent, HeaderComponent],
  imports: [CommonModule, RouterModule, MatToolbarModule, HeaderMenuModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {}
