import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthConfigModule } from '@core/auth/auth-config.module';
import { AuthModule } from '@core/auth/auth.module';
import { QUILL_CONFIG } from '@shared/constants/quill-config';
import { AppConfigService } from '@shared/services/app.config.service';
import { QuillModule, provideQuillConfig } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthConfigModule,
    AuthModule,
    LayoutModule,
    QuillModule.forRoot({}),
  ],
  providers: [provideQuillConfig(QUILL_CONFIG),
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService) => () => appConfig.loadAppConfig(),
      deps: [AppConfigService],
      multi: true
    },
    AppConfigService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
