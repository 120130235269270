import { Injectable } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { environment } from '@env/environment';

import { AppConfigService } from './app.config.service';

@Injectable({
  providedIn: 'root'
})
export class Auth0ConfigService {
  protected readonly authClientConfig: AuthClientConfig;

  constructor(
    private readonly _appConfig: AppConfigService,
    private readonly _authClientConfig: AuthClientConfig
  ) {
    this.authClientConfig = _authClientConfig;
  }

  public initializeAuth0(): Promise<void> {
    const auth0 = environment.auth0;
    const appConfig = this._appConfig.getAppConfig();
    this.authClientConfig.set({
      clientId: appConfig.auth0.clientId,
      domain: appConfig.auth0.domain,
      authorizationParams: {
        redirect_uri: auth0.authorizationParams.redirect_uri,
      },
    });

    return Promise.resolve();
  }
}
