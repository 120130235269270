import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '@core/auth/services/user-auth.service';
import { DestroyerHelper } from '@core/helpers/destroyer-helper';
import { IUser } from '@core/models/auth';
import { nexItemMenu } from '@triparc/nexus';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends DestroyerHelper {
  authService = inject(UserAuthService);
  isAuthenticatedAsync$ = this.authService.isAuthenticated$;
  user: IUser | null = null;

  readonly nexListItemMenu: nexItemMenu[] = [];

  constructor(public router: Router) {
    super();
    this.subscribers.add(
      this.authService.user$.subscribe((user) => (this.user = user as IUser))
    );
  }

  login() {
    this.authService.loginWithRedirect();
  }

  logout() {
    this.authService.logoutWithRedirect();
  }

  redirectTo(route: string) {
    if (route.length > 0) {
      this.router.navigate([route]);
    }
  }
}
